.crud-table {
  thead {
    tr {
      th:first-child {
        padding-left: 16px !important;
      }
    }
  }
  tbody {
    tr {
      transition: background 300ms ease;
      &:hover {
        background: $light-gray;
      }
      td {
        border-bottom: none;
        text-transform: capitalize;
        padding-bottom: 1rem;
      }
      td:first-child {
        padding-left: 16px !important;
      }
    }
  }
}

.crud-table-select {
  thead {
    tr {
      th:first-child {
        padding-left: 16px !important;
      }
    }
  }
  tbody {
    tr {
      transition: background 300ms ease;
      td {
        border-bottom: none;
        text-transform: capitalize;
        cursor: pointer;
      }
      td:first-child {
        padding-left: 16px !important;
      }
    }
  }
}

.selected-row {
  background-color: 
  #FBBF24;
}

.not-selected-row {
  background-color: #fff;
}


//manage-url table
.crud-table-manage-url {
  thead {
    tr {
      th:first-child {
        padding-left: 16px !important;
        width: 40% !important;
      }
    }
  }
  tbody {
    tr {
      transition: background 300ms ease;
      &:hover {
        background: $light-gray;
      }
      td {
        border-bottom: none;
        text-transform: capitalize;
        padding-bottom: 1rem;
      }
      td:first-child {
        padding-left: 16px !important;
      }
    }
  }
}